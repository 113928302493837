<template>
	<Breadcrumbs></Breadcrumbs>
	<MDBCard class="m-3">
    <MDBCardBody>
			<transition name="fade">
				<PageLoading v-if="PageLoading"></PageLoading>
			</transition>
		</MDBCardBody>
  </MDBCard>
</template>

<script>
import { MDBCard, MDBCardBody } from 'mdb-vue-ui-kit';
import Breadcrumbs from '@/components/Breadcrumbs';
import PageLoading from '@/components/Elements/PageLoading';
import { ref } from "vue";

export default {
  name: "TechnologyCategory",
  components: {
		MDBCard,
		Breadcrumbs,
		MDBCardBody,
		PageLoading
  },
  setup() {
		const PageLoading = ref(true);

		return {
			PageLoading
		};
  },
  mounted() {
    Promise.all([
    ]).then(() => {
      this.PageLoading = false;
    });
  }
};
</script>